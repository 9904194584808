import Splide from '@splidejs/splide';
import { Fancybox } from '@fancyapps/ui';

(function () {
  'use strict';

  const selectors = {
    splide: '.insta-feed__gallery-container'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'loop',
          autoplay: false,
          drag: true,
          width: '100%',
          speed: 1000,
          gap: 42,
          perPage: 3,
          destroy: true,
          perMove: 1,
          breakpoints: {
            1024: {
              destroy: false,
              perPage: 2,
              drag: true
            },
            640: {
              destroy: false,
              perPage: 2,
              drag: true
            }
          },
          arrows: false,
          pagination: true,
          classes: {
            pagination: 'splide__pagination insta-feed__pagination',
            arrow: 'splide__arrow insta-feed__arrow',
            prev: 'splide__arrow--prev insta-feed__arrow--prev',
            next: 'splide__arrow--next insta-feed__arrow--next'
          }
        }).mount();
      }
    });

    Fancybox.bind('[data-fancybox="gallery"]', {});
  };

  window.addEventListener('load', init);
})();
