(function () {
  'use strict';

  const breakpoints = {
    mobile: 640,
    tablet: 1024,
    desktop: 1360
  };

  const classes = {
    bigCardClass: 'card-agenda__card-single-big',
    smallCardClass: 'card-agenda__card-single-small',
    transitionCardClass: 'card-agenda__card-single--transition',
    disabledButton: 'is-disabled'
  };

  const selectors = {
    caButtons: '.js-ca-button',
    smallCardClass: '.card-agenda__card-single-small',
    listContainer: '.card-agenda__card-container-list',
    jsCardImage: '.js-card-agenda__card-image',
    caCards: '.card-agenda__card-single'
  };

  const init = () => {
    const caButtons = document.querySelectorAll(selectors.caButtons);
    const caCards = document.querySelectorAll(selectors.caCards);
    const listContainer = document.querySelector(selectors.listContainer);
    let currentIndexCaCard = 1;

    const getSizeCard = () => {
      const caCardsSmall = document.querySelector(selectors.smallCardClass);
      if (caCardsSmall.classList.contains('card-agenda__card-single-big')) {
        const windowWidth = window.innerWidth;
        if (windowWidth <= breakpoints.mobile) return caCards[caCards.length - 1].getBoundingClientRect().width;
        if (windowWidth <= breakpoints.tablet) return caCards[caCards.length - 1].getBoundingClientRect().width;
        return caCards[caCards.length - 1].getBoundingClientRect().width;
      } else {
        const windowWidth = window.innerWidth;
        if (windowWidth <= breakpoints.mobile) return caCardsSmall.getBoundingClientRect().width;
        if (windowWidth <= breakpoints.tablet) return caCardsSmall.getBoundingClientRect().width;
        return caCardsSmall.getBoundingClientRect().width;
      }
    };

    const getStep = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= breakpoints.mobile) return 10;
      if (windowWidth <= breakpoints.tablet) return 20;
      return 50;
    };

    const updateButtonState = () => {
      const prevButton = document.querySelector(`${selectors.caButtons}[data-type="ca-previous"]`);
      const nextButton = document.querySelector(`${selectors.caButtons}[data-type="ca-next"]`);

      if (prevButton instanceof HTMLElement) {
        prevButton.disabled = currentIndexCaCard === 1;
      }
      if (nextButton instanceof HTMLElement) {
        nextButton.disabled = currentIndexCaCard === caCards.length;
      }
    };

    const moveSlide = (direction) => {
      const sizeCard = getSizeCard();
      const distance = sizeCard + 42; // Calcul de la distance à parcourir
      const listContainerHeight = listContainer.getBoundingClientRect().height;

      listContainer.style.height = listContainerHeight + 'px';

      let targetTransform;

      // Déterminer la position cible selon la direction
      if (direction === 'prev' && currentIndexCaCard > 1) {
        currentIndexCaCard--;
        targetTransform = (-currentIndexCaCard * distance) + distance;
      } else if (direction === 'next' && currentIndexCaCard < caCards.length) {
        targetTransform = -currentIndexCaCard * distance;
        currentIndexCaCard++;
      } else {
        return; // Ne fait rien si on est à la première ou dernière carte
      }

      let currentTransform = parseFloat(listContainer.style.transform.match(/translateX\(([^)]+)\)/)?.[1]) || 0;
      const step = getStep(); // Pas de l'animation par itération
      const diff = targetTransform - currentTransform;
      const directionFactor = diff > 0 ? 1 : -1; // Direction de l'animation

      const animate = () => {
        currentTransform += directionFactor * step;

        if ((directionFactor === 1 && currentTransform >= targetTransform) || (directionFactor === -1 && currentTransform <= targetTransform)) {
          currentTransform = targetTransform; // On assure qu'on finit exactement à la cible
          listContainer.style.transform = `translateX(${currentTransform}px)`; // Appliquer la transformation finale
          return;
        }

        listContainer.style.transform = `translateX(${currentTransform}px)`;

        requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);

      // Appliquer les classes pour la carte active
      caCards.forEach((card, index) => {
        card.classList.toggle(classes.bigCardClass, index + 1 === currentIndexCaCard);
      });

      // Mettre à jour l'état des boutons (suivant/précédent)
      updateButtonState();
    };

    let currentTransform = 0; // Position de transformation initiale
    let startX = 0; // Position du début du toucher

    const handleTouchStart = (e) => {
      startX = e.touches[0].clientX; // Capture la position de départ du toucher
      currentTransform = parseFloat(listContainer.style.transform.match(/translateX\(([^)]+)\)/)?.[1]) || 0;
    };

    const handleTouchMove = (e) => {
      const touchX = e.touches[0].clientX; // Position actuelle du doigt
      const deltaX = touchX - startX; // Calcul du déplacement du doigt par rapport à la position de départ

      const newTransform = currentTransform + deltaX;

      listContainer.style.transition = 'none'; // Désactive la transition pour un déplacement instantané
      listContainer.style.transform = `translateX(${newTransform}px)`;
    };

    const handleTouchEnd = (e) => {
      const endX = e.changedTouches[0].clientX; // Position de fin du doigt
      const deltaX = endX - startX; // Différence entre la position de départ et de fin

      const threshold = 50; // Seuil de mouvement pour glisser entre les cartes
      let newTransform;

      if (deltaX > threshold) {
        moveSlide('prev'); // Glisse vers la carte précédente
        newTransform = currentTransform + threshold;
      } else if (deltaX < -threshold) {
        moveSlide('next'); // Glisse vers la carte suivante
        newTransform = currentTransform - threshold;
      } else {
        newTransform = currentTransform;
      }

      currentTransform = newTransform; // Mettre à jour la position globale du conteneur
    };

    // Gestion des boutons de navigation
    caButtons.forEach((button) => {
      button.addEventListener('click', (e) => {
        const type = e.currentTarget.dataset.type;
        moveSlide(type === 'ca-previous' ? 'prev' : 'next');
      });
    });

    if (listContainer instanceof HTMLElement) {
      listContainer.addEventListener('touchstart', handleTouchStart);
      listContainer.addEventListener('touchmove', handleTouchMove);
      listContainer.addEventListener('touchend', handleTouchEnd);
    }

    updateButtonState();
  };

  const delayedImage = () => {
    const jsCardImage = document.querySelectorAll(selectors.jsCardImage);

    jsCardImage.forEach(image => {
      const imageSrc = image.getAttribute('data-src');

      if (imageSrc) {
        image.src = `${imageSrc}`;
      }
    });
  };

  document.addEventListener('DOMContentLoaded', delayedImage);
  window.addEventListener('load', init);
})();
