(function () {
  'use strict';

  const selectors = {
    burger: '.js-burger-trigger',
    closeButton: '.close__nav__button',
    navigationLinks: '.js-navigation-link',
    checkboxSelector: 'checkbox_toggle',
    navigationScroll: '.js-header',
    navigationTop: '.js-navigation-top',
    iconSvg: '.js-mobile-icon-svg',
    stage: '.js-stage',
    navigationLayer: '.js-navigation-layer__logo-desktop',
    asideSectionTemplate: '.template-single__second-column-wrapper',
    singleUnderNavigation: '.template-single__first-navigation',
    firstItemActive: '.js-first-item-menu--active',
    singleIntro: '.template-single__first-intro'
  };

  const init = () => {
    const burger = document.querySelector(selectors.burger);
    const closeButton = document.querySelector(selectors.closeButton);
    const navigationLinks = document.querySelectorAll(selectors.navigationLinks);
    const navigationScroll = document.querySelector(selectors.navigationScroll);
    const navigationTop = document.querySelector(selectors.navigationTop);
    const body = document.querySelector('body');
    const iconSvg = document.querySelector('.js-mobile-icon-svg');
    const navigationLayer = document.querySelector(selectors.navigationLayer);
    const iconSvgUse = document.querySelector('.js-mobile-icon-svg use');
    const stage = document.querySelector(selectors.stage);
    const logoStage = document.querySelector('.js-logo-stage use');
    const asideSectionTemplate = document.querySelector(selectors.asideSectionTemplate);
    const singleUnderNavigation = document.querySelector(selectors.singleUnderNavigation);
    const singleIntro = document.querySelector(selectors.singleIntro);

    window.addEventListener('scroll', () => {
      checkStage();
      if (singleUnderNavigation instanceof HTMLElement) {
        checkUnderNavigation();
      }
    });
    checkStage();
    if (singleUnderNavigation instanceof HTMLElement) {
      checkUnderNavigation();
    }

    function checkStage () {
      if (stage instanceof HTMLElement) {
        const stageHeight = stage.offsetHeight;
        const scrollTop = window.scrollY;

        if (scrollTop >= stageHeight - 170) {
          document.body.classList.add('scrolled-past-stage');
          logoStage.setAttribute('xlink:href', '#customer-logo');
        } else {
          document.body.classList.remove('scrolled-past-stage');
          if (!document.body.classList.contains('contrasted')) {
            logoStage.setAttribute('xlink:href', '#customer-logo-white');
          } else {
            logoStage.setAttribute('xlink:href', '#customer-logo');
          }
        }
      }
    }

    function checkUnderNavigation () {
      if (singleUnderNavigation instanceof HTMLElement) {
        const singleUnderNavHeight = singleIntro.offsetTop;
        const scrollTop = window.scrollY;

        if (scrollTop >= singleUnderNavHeight - 120) {
          singleUnderNavigation.classList.add('template-single__first-navigation--scrolled');
        } else {
          singleUnderNavigation.classList.remove('template-single__first-navigation--scrolled');
        }

        const links = document.querySelectorAll('.template-single__first-navigation-link');
        let activeElementFound = false;

        links.forEach(link => {
          const href = link.getAttribute('href');
          if (href && href.startsWith('#')) {
            const id = href.substring(1);
            const targetElement = document.getElementById(id);

            if (targetElement) {
              const elementTop = targetElement.offsetTop;
              const elementBottom = elementTop + targetElement.offsetHeight;

              if (scrollTop >= elementTop - 120 && scrollTop < elementBottom - 120) {
                link.parentElement.classList.add('template-single__first-navigation-li--active');
                activeElementFound = true;
              } else {
                link.parentElement.classList.remove('template-single__first-navigation-li--active');
              }
            }
          }
        });
      }
    }

    function checkStageSinglePage () {
      if (stage instanceof HTMLElement) {
        const stageHeight = stage.offsetHeight;
        const scrollTop = window.scrollY;

        if (asideSectionTemplate instanceof HTMLElement) {
          if (!(scrollTop >= stageHeight + 240)) {
            asideSectionTemplate.style.transform = 'translateY(0)';
          }
        }
      }
    }

    if (navigationScroll instanceof HTMLElement && navigationTop instanceof HTMLElement) {
      let lastScrollTop = 0;
      let ticking = false;

      window.addEventListener('scroll', function () {
        if (window.innerWidth >= 1360) {
          if (!ticking) {
            window.requestAnimationFrame(function () {
              const st = window.pageYOffset || document.documentElement.scrollTop;

              if (st > lastScrollTop) {
                iconSvg.classList.add('navigation__logo-icon--resized');
                navigationLayer.classList.add('navigation-layer__logo--resized');

                if (document.body.classList.contains('scrolled-past-stage')) {
                  iconSvgUse.setAttribute('xlink:href', '#customer-logo-nav-mobile');
                } else {
                  iconSvgUse.setAttribute('xlink:href', '#customer-logo-white-nav-mobile');
                }

                if (asideSectionTemplate instanceof HTMLElement && body.classList.contains('scrolled-past-stage')) {
                  asideSectionTemplate.style.transform = 'translateY(96px)';
                }
                checkStageSinglePage();
                navigationScroll.style.transform = `translateY(${navigationTop.offsetHeight * -1}px)`;

                if (singleUnderNavigation instanceof HTMLElement) {
                  singleUnderNavigation.classList.remove('template-single__first-navigation--scrolled-stage');
                }
              } else {
                iconSvg.classList.remove('navigation__logo-icon--resized');
                navigationLayer.classList.remove('navigation-layer__logo--resized');
                checkStage();
                if (asideSectionTemplate instanceof HTMLElement && body.classList.contains('scrolled-past-stage')) {
                  asideSectionTemplate.style.transform = `translateY(${140 + navigationTop.offsetHeight}px)`;
                }
                checkStageSinglePage();
                navigationScroll.style.transform = 'translateY(0)';

                if (singleUnderNavigation instanceof HTMLElement) {
                  singleUnderNavigation.classList.add('template-single__first-navigation--scrolled-stage');
                }
              }

              lastScrollTop = st <= 0 ? 0 : st;
              ticking = false;
            });

            ticking = true;
          }
        } else {
          navigationScroll.style.transform = 'translateY(0)';
        }
      });
    }

    if (burger instanceof HTMLElement) {
      burger.addEventListener('click', function () {
        const currentExpanded = burger.getAttribute('aria-expanded');

        currentExpanded === 'true' ? body.classList.remove('fixed') : body.classList.add('fixed');

        const newExpanded = currentExpanded === 'true' ? 'false' : 'true';

        burger.setAttribute('aria-expanded', newExpanded);
      });
    }

    if (closeButton instanceof HTMLElement) {
      closeButton.addEventListener('click', function () {
        const checkboxToggle = document.getElementById(selectors.checkboxSelector);

        checkboxToggle.checked = false;
      });
    }

    navigationLinks.forEach(navigationLink => {
      navigationLink.addEventListener('click', function (e) {
        if (e.currentTarget.getAttribute('target') === '_blank') {
          return;
        }

        const checkboxToggle = document.getElementById(selectors.checkboxSelector);

        checkboxToggle.checked = false;
      });
    });

    document.querySelectorAll('a[href^="#"]:not(.js-wpml-ls-item-toggle)').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const target = document.querySelector(this.getAttribute('href'));
        if (target) {
          const offset = 96;
          const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - offset;

          window.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
          });
        }
      });
    });

    document.querySelector('.navigation__avoid__link').addEventListener('click', function (e) {
      const target = document.querySelector('#stage');
      if (target) {
        target.setAttribute('tabindex', '-1');
        target.focus();
      }
    });
  };

  window.addEventListener('load', init);
  window.addEventListener('resize', () => {
    const singleUnderNavigation = document.querySelector(selectors.singleUnderNavigation);
    const asideSectionTemplate = document.querySelector(selectors.asideSectionTemplate);

    if (window.innerWidth < 1360) {
      if (singleUnderNavigation instanceof HTMLElement) {
        singleUnderNavigation.classList.remove('template-single__first-navigation--scrolled-stage');
      }

      if (asideSectionTemplate instanceof HTMLElement) {
        asideSectionTemplate.style.transform = 'translateY(0)';
      }
    }
  });
})();
