import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    filterButton: '.js-filter-button-activator',
    filterInputPast: '.js-filter-input',
    filterLabelPast: '.js-filter-label',
    filterContainer: '.js-filter-container'
  };

  const init = () => {
    const filterButton = document.querySelector(selectors.filterButton);
    const filterContainer = document.querySelector(selectors.filterContainer);
    const filterInputPast = document.querySelector(selectors.filterInputPast);
    const filterLabelPast = document.querySelector(selectors.filterLabelPast);

    if (filterButton instanceof HTMLElement) {
      filterButton.addEventListener('click', function () {
        const state = filterButton.dataset.state;
        const message = filterButton.dataset.message;
        const currentText = filterButton.textContent;

        state === 'close' ? filterContainer.classList.add('filter--active') : filterContainer.classList.remove('filter--active');
        state === 'close' ? filterButton.dataset.state = 'open' : filterButton.dataset.state = 'close';
        filterButton.textContent = message;
        filterButton.dataset.message = currentText;
      });
    }

    if (filterInputPast instanceof HTMLElement) {
      filterInputPast.addEventListener('click', pastEventToggle);
    }

    if (filterLabelPast instanceof HTMLElement) {
      filterLabelPast.addEventListener('click', pastEventToggle);
    }

    function pastEventToggle() {
      const currentUrl = new URL(window.location.href);
      if (currentUrl.searchParams.has('past-event')) {
        currentUrl.searchParams.delete('past-event');
      } else {
        currentUrl.searchParams.set('past-event', 'true');
      }
      window.location.href = currentUrl.toString();
    }
  };

  window.addEventListener('load', init);
})();
