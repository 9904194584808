import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-stage-slider',
    logoStage: '.js-logo-stage',
    cardsSelector: '.js-card-selector',
    delayedVideo: '.js-delayed-video',
    playVideoButton: '.js-play-video-mobile',
    stopVideoButton: '.js-stop-video-mobile',
    coverVideo: '.js-video-cover',
    progressBar: '.js-selector-progress'
  };

  const init = () => {
    let currentIndex = 1;
    const splides = document.querySelectorAll(selectors.splide);
    const cardsSelector = document.querySelectorAll(selectors.cardsSelector);
    const playVideoButton = document.querySelector(selectors.playVideoButton);
    const stopVideoButton = document.querySelector(selectors.stopVideoButton);

    // TODO : Gérer le clique sur une card pour ajuste la progress bar.

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        const splideElement = new Splide(splide, {
          type: 'slide',
          rewind: true,
          autoplay: false,
          speed: 1000,
          perPage: 1,
          gap: 0,
          arrows: false,
          pagination: false
        }).mount();

        function fillProgressBar (index) {
          const progress = document.querySelector(`.js-selector-progress[data-slide="${index - 1}"][data-progress="${index}"]`);

          function updateProgress () {
            if (progress.value < progress.max) {
              progress.value += 0.15;
              requestAnimationFrame(updateProgress);
            } else {
              progress.value = 0;
              if (currentIndex === 3) {
                currentIndex = 0;
              }
              splideElement.go(parseInt(currentIndex));
              currentIndex++;
              fillProgressBar(currentIndex);
            }
          }

          requestAnimationFrame(updateProgress);
        }

        fillProgressBar(currentIndex);

        for (const cardSelector of cardsSelector) {
          if (cardSelector instanceof HTMLElement) {
            cardSelector.addEventListener('click', (e) => {
              splideElement.go(parseInt(e.currentTarget.dataset.progress) - 1);
            });
          }
        }
      }
    });

    const playVideo = () => {
      const delayedVideos = document.querySelectorAll(selectors.delayedVideo);
      const coverVideos = document.querySelectorAll(selectors.coverVideo);

      if (playVideoButton instanceof HTMLElement && playVideoButton instanceof HTMLElement) {
        playVideoButton.classList.add('stage__background-image-button--hidden');
        stopVideoButton.classList.remove('stage__background-image-button--hidden');

        delayedVideo();

        coverVideos.forEach(cover => {
          cover.remove();
        });

        delayedVideos.forEach(video => {
          const videoSrc = video.getAttribute('data-src');
          if (videoSrc) {
            video.setAttribute('src', videoSrc);
          }

          video.play();
        });
      }
    };

    const stopVideo = () => {
      const delayedVideos = document.querySelectorAll(selectors.delayedVideo);

      playVideoButton.classList.remove('stage__background-image-button--hidden');
      stopVideoButton.classList.add('stage__background-image-button--hidden');

      delayedVideos.forEach(video => {
        video.pause();
        video.currentTime = 0;
      });
    };

    if (playVideoButton instanceof HTMLElement) {
      playVideoButton.addEventListener('click', () => {
        playVideo();
      });
    }

    if (stopVideoButton instanceof HTMLElement) {
      stopVideoButton.addEventListener('click', () => {
        stopVideo();
      });
    }

    playVideo();
  };

  const delayedVideo = () => {
    const delayedVideos = document.querySelectorAll(selectors.delayedVideo);

    delayedVideos.forEach(video => {
      const videoSrc = video.getAttribute('data-src');

      if (videoSrc) {
        const sourceMP4 = document.createElement('source');
        sourceMP4.src = `${videoSrc}#t=0.1`;
        sourceMP4.type = 'video/mp4';
        video.appendChild(sourceMP4);
      }
    });
  };

  window.addEventListener('load', init);
})();
