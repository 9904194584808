import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-card-guide'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        new Splide(splide, {
          type: 'slide',
          rewind: true,
          autoplay: false,
          speed: 1000,
          perPage: 1,
          padding: {
            right: '12.5%',
            left: '5%'
          },
          mediaQuery: 'min',
          breakpoints: {
            640: {
              destroy: true
            }
          },
          gap: 24,
          arrows: false,
          pagination: false
        }).mount();
      }
    });
  };

  window.addEventListener('load', init);
})();
