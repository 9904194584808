(function () {
  'use strict';

  const selectors = {
    stage: '.js-stage',
    contrasteToggle: '#contraste-toggle'
  };

  const init = () => {
    const contrasteToggle = document.querySelector(selectors.contrasteToggle);
    const stage = document.querySelector(selectors.stage);
    const logoStage = document.querySelector('.js-logo-stage use');

    const isContrasted = localStorage.getItem('contrasted') === 'true';
    if (isContrasted) {
      document.body.classList.add('contrasted');
    }

    if (contrasteToggle instanceof HTMLElement) {
      contrasteToggle.checked = isContrasted;

      contrasteToggle.addEventListener('click', () => {
        if (contrasteToggle.checked) {
          document.body.classList.add('contrasted');
          localStorage.setItem('contrasted', 'true');
          checkStage();
        } else {
          document.body.classList.remove('contrasted');
          localStorage.setItem('contrasted', 'false');
          checkStage();
        }
      });

      function checkStage () {
        if (stage instanceof HTMLElement) {
          const stageHeight = stage.offsetHeight;
          const scrollTop = window.scrollY;

          if (scrollTop >= stageHeight - 170) {
            document.body.classList.add('scrolled-past-stage');
            logoStage.setAttribute('xlink:href', '#customer-logo');
          } else {
            document.body.classList.remove('scrolled-past-stage');
            if (!document.body.classList.contains('contrasted')) {
              logoStage.setAttribute('xlink:href', '#customer-logo-white');
            } else {
              logoStage.setAttribute('xlink:href', '#customer-logo');
            }
          }
        }
      }
    }
  };

  window.addEventListener('load', init);
})();
