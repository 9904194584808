(function () {
  'use strict';

  const classes = {
    buttonActive: 'accordion__question__btn--active',
    questionActive: 'accordion__question--active'
  };

  const selectors = {
    accordionItem: '.accordion__item',
    accordionDescriptionWrapper: '.accordion__description__wrapper',
    accordionQuestion: '.accordion__question',
    accordionQuestionButton: '.js-accordion__question__btn'
  };

  const init = () => {
    const accordions = document.querySelectorAll(selectors.accordionItem);

    const toggleAccordion = (accordion, index) => {
      const isOpen = accordion.querySelector(selectors.accordionDescriptionWrapper).style.height === `${accordion.querySelector(selectors.accordionDescriptionWrapper).scrollHeight}px`;

      accordions.forEach((a, i) => {
        if (a instanceof HTMLElement) {
          const content = a.querySelector(selectors.accordionDescriptionWrapper);
          const icon = a.querySelector(selectors.accordionQuestionButton);
          const question = a.querySelector(selectors.accordionQuestion);

          content.style.height = i === index && !isOpen ? `${content.scrollHeight}px` : '0px';
          icon.classList.toggle(classes.buttonActive, i === index && !isOpen);
          question.classList.toggle(classes.questionActive, i === index && !isOpen);
        }
      });
    };

    accordions.forEach((accordion, index) => {
      if (accordion instanceof HTMLElement) {
        const header = accordion.querySelector(selectors.accordionQuestion);
        const content = accordion.querySelector(selectors.accordionDescriptionWrapper);

        content.addEventListener('click', () => toggleAccordion(accordion, index));
        header.addEventListener('click', () => toggleAccordion(accordion, index));
      }
    });
  };

  window.addEventListener('load', init);
})();
