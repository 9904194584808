import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-slider-activity',
    cardsSelector: '.js-card-selector',
    dataListDt: '.js-slider-activity-dt',
    dataListDd: '.js-slider-activity-dd',
    previousBtns: '.js-bullet-previous',
    nextBtns: '.js-bullet-next',
    bullets: '.js-bullet',
    sliderCards: '.slider-activity__card',
    svgMaps: '.slider-activity__card-maps',
    cartBase: '.js-cart-element-base',
    cartActive: '.js-cart-element-active'
  };

  const init = () => {
    let currentIndex = 0;
    const splides = document.querySelectorAll(selectors.splide);
    const cardsSelector = document.querySelectorAll(selectors.cardsSelector);
    const dataListDt = document.querySelectorAll(selectors.dataListDt);
    const dataListDd = document.querySelectorAll(selectors.dataListDd);
    const previousBtns = document.querySelectorAll(selectors.previousBtns);
    const nextBtns = document.querySelectorAll(selectors.nextBtns);
    const bullets = document.querySelectorAll(selectors.bullets);
    const cartBase = document.querySelectorAll(selectors.cartBase);
    const cartActive = document.querySelectorAll(selectors.cartActive);
    const sliderCards = document.querySelectorAll(selectors.sliderCards);

    function changeMaps () {
      cartBase.forEach(cartBaseElement => {
        cartBaseElement.style.opacity = 1;
      });

      cartActive.forEach(cartActiveElement => {
        cartActiveElement.style.opacity = 0;
      });

      sliderCards.forEach(sliderCard => {
        if (sliderCard.classList.contains('is-active')) {
          document.querySelector(`#${sliderCard.dataset.maps}-base`).style.opacity = '0';
          document.querySelector(`#${sliderCard.dataset.maps}-active`).style.opacity = '1';
        }
      });
    }

    function removeActiveClass () {
      dataListDt.forEach(dataListElement => {
        dataListElement.classList.remove('slider-activity__card-content-dt--active');
        dataListElement.classList.add('slider-activity__card-content-dt--inactive');
      });

      dataListDd.forEach(dataListElement => {
        dataListElement.classList.remove('slider-activity__card-content-dd--active');
        dataListElement.classList.add('slider-activity__card-content-dd--inactive');
      });
    }

    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        const splideElement = new Splide(splide, {
          type: 'slide',
          rewind: false,
          autoplay: false,
          drag: false,
          perPage: 1,
          gap: 0,
          arrows: false,
          pagination: false
        }).mount();

        changeMaps();

        const totalSlides = sliderCards.length; // Gère dynamiquement le nombre de slides

        setInterval(() => {
          //currentIndex = (currentIndex + 1) % totalSlides;
          //splideElement.go(currentIndex);
          //changeMaps();
        }, 10000);

        splideElement.on('move', () => {
          currentIndex = splideElement.index;
          bullets.forEach(bullet => bullet.querySelector('use').setAttribute('xlink:href', '#bullet'));
          const activeBullets = document.querySelectorAll(`[data-bullet="${currentIndex}"] use`);
          activeBullets.forEach(bullet => bullet.setAttribute('xlink:href', '#bullet-active'));
        });

        splideElement.on('moved', () => {
          changeMaps();
        });

        const handleButtonClick = (increment) => {
          currentIndex = (currentIndex + increment + totalSlides) % totalSlides;
          splideElement.go(currentIndex);
          changeMaps();
        };

        nextBtns.forEach(nextBtn => nextBtn.addEventListener('click', () => handleButtonClick(1)));
        previousBtns.forEach(previousBtn => previousBtn.addEventListener('click', () => handleButtonClick(-1)));

        cardsSelector.forEach(cardSelector => {
          if (cardSelector instanceof HTMLElement) {
            cardSelector.addEventListener('click', (e) => {
              const newIndex = parseInt(e.currentTarget.dataset.progress, 10) - 1;
              if (!isNaN(newIndex)) {
                currentIndex = newIndex;
                splideElement.go(currentIndex);
                changeMaps();
              }
            });
          }
        });

        dataListDt.forEach(dataListElement => {
          if (dataListElement instanceof HTMLElement) {
            dataListElement.addEventListener('click', (e) => {
              removeActiveClass();
              e.currentTarget.classList.add('slider-activity__card-content-dt--active');
              const element = document.querySelector(`.js-slider-activity-dd[data-activity='${currentIndex}'][data-type='${e.currentTarget.dataset.type}']`);
              if (element instanceof HTMLElement) {
                element.classList.remove('slider-activity__card-content-dd--inactive');
                element.classList.add('slider-activity__card-content-dd--active');
              }
            });
          }
        });
      }
    });
  };

  window.addEventListener('load', init);
})();
